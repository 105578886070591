<template>
  <div>
    <div class="bgStyle cardContent">
      <div class="pageTitle">项目总览</div>
      <div class="cardInfo">
        <div
          class="projectInfo"
          :class="{ activeGroud: activeProjectState == 0 }"
          @click="taskscreening(0)"
        >
          <div>
            <span class="status">全部</span>
            <span class="fontBlue" v-text="totalProjectCount"></span>
          </div>
          <div class="desc">包含我负责的以及我参与的全部项目</div>
        </div>
        <div
          class="projectInfo blueInfo"
          :class="{ activeGroud: activeProjectState == 1 }"
          @click="taskscreening(1)"
        >
          <div>
            <span class="status">正常进行</span>
            <span class="fontBlue" v-text="normalCount"></span>
          </div>
          <div class="colorBox blue"></div>
        </div>
        <div
          class="projectInfo pinkInfo"
          :class="{ activeGroud: activeProjectState == 2 }"
          @click="taskscreening(2)"
        >
          <div>
            <span class="status">已延期</span>
            <span class="fontPink" v-text="delayCount"></span>
          </div>
          <div class="colorBox pink"></div>
        </div>
      </div>
    </div>
    <TaskOverview :taskscreen="taskscreen" />
    <TaskList :taskstatusselecteds="taskstatusselected" />
  </div>
</template>

<script>
import TaskList from "@/views/Home/components/TaskList.vue";
import TaskOverview from "@/views/Home/components/TaskOverview.vue";
import { getDashBoard } from "@/api/home.js";
export default {
  components: {
    TaskList,
    TaskOverview,
  },
  data() {
    return {
      taskscreen: "0",
      taskstatusselected: 0,
      datasource: [],
      totalProjectCount: 0, //项目总数
      normalCount: 0, //正常项目数量
      delayCount: 0, //延期项目数量
      activeProjectState: 0,
    };
  },
  methods: {
    taskscreening(res) {
      this.taskstatusselected = res;
      this.activeProjectState = res;
      this.taskscreen = res.toString();
    },
    getDashBoards(parm) {
      getDashBoard(parm).then((res) => {
        this.totalProjectCount = res.projectOverView.allCount;
        this.normalCount = res.projectOverView.normalCount;
        this.delayCount = res.projectOverView.delayCount;
      });
    },
  },
  mounted() {
    this.getDashBoards({});
  },
};
</script>

<style lang="scss" scoped>
.cardContent {
  .cardInfo {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    .projectInfo {
      width: 280px;
      border: 1px solid $dark_gray_color;
      padding: 10px;
      margin: 0 60px;

      // min-width: 280px;
      &.allInfo {
        background: #eeecec;
      }

      &.blueInfo {
        border-left: 5px solid #2d8cf0;
      }

      &.pinkInfo {
        border-left: 5px solid #ff6666;
      }

      &.activeGroud {
        background: #eeecec;
      }

      .status {
        font-size: 15px;
        font-weight: 600;
      }

      .desc {
        padding-top: 10px;
      }

      .fontBlue {
        color: #2d8cf0;
        font-size: 18px;
        font-weight: 600;
        padding-left: 6px;
      }

      .fontPink {
        color: #ff6666;
        font-size: 18px;
        font-weight: 600;
        padding-left: 6px;
      }

      .colorBox {
        width: 100px;
        height: 10px;
        margin: 15px auto 0;
      }

      .blue {
        background: #2d8cf0;
      }

      .pink {
        background: #ff6666;
      }
    }
  }
}
</style>
