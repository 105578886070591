<template>
  <div class="bgStyle">
    <div class="pageTitle">任务总览</div>
    <div class="container">
      <a-row class="title">
        <a-col :span="16">
          <div id="tasktotalcount">
            任务总数：<span>{{ tasktotalcounts }}</span>
          </div>
        </a-col>
        <a-col :span="8">
          <h2>任务责任人</h2>
        </a-col>
      </a-row>
      <a-row style="overflow:auto">
        <a-col :span="8">
          <TaskOverViewPie
            :pieData="pieData"
            id="pie1Dom"
            :pieId="pieDataId"
            :titles="pie1DataTitles"
            :pieIndex=1
            class="echarts echarts-pie"
          />
        </a-col>
        <a-col :span="8">
          <TaskOverViewPie2
            :pieData="pieData2"
            id="pie2Dom"
            :pieId="pieDataId2"
            :titles="pie2DataTitles"
            :pieIndex=2
            class="echarts echarts-pie"
          />
        </a-col>
        <a-col :span="8">
          <TaskResponsibilityColumnar
            :datasources="datasources"
            class="echarts echarts-bar"
          />
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import { getDashBoard } from "/src/api/home.js";
import TaskOverViewPie from "@/components/TaskOverViewPie";
import TaskOverViewPie2 from "@/components/TaskOverViewPie";
import TaskResponsibilityColumnar from "@/components/TaskResponsibilityColumnar";
export default {
  name: "Echarts",
  props: {
    taskscreen: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      tasktotalcounts: 0, //项目总数
      pie1DataTitles: ["未启动","进行中","待验收","已完成"],
      pie2DataTitles: ["正常", "延期"],
      pieDataId: "pie1Dom",
      pieDataId2: "pie2Dom",
      pieData: [
        {
          value: 0,
          name: "未启动",
        },
        {
          value: 0,
          name: "进行中",
        },
        {
          value:0,
          name:"待验收",
        },
        {
          value: 0,
          name: "已完成",
        },
      ],
      pieData2: [
        { value: 0, name: "正常"},
        { value: 0, name: "延期"},
      ],
      datasources: [],
    };
  },
  components: { TaskOverViewPie, TaskOverViewPie2, TaskResponsibilityColumnar },
  mounted() {
    var parm = {
      status: this.taskscreen,
    };
    getDashBoard(parm).then((res) => {
      this.tasktotalcounts = res.taskOverView.allCount;

      this.pieData[0]["value"] = res.taskOverView.unallocatedCount;
      this.pieData[1]["value"] = res.taskOverView.onDoingCount;
      this.pieData[2]["value"] = res.taskOverView.waitingAcceptCount;
      this.pieData[3]["value"] = res.taskOverView.doneCount;

      this.pieData2[0]["value"] = res.taskOverView.normalCount;
      this.pieData2[1]["value"] = res.taskOverView.delayCount;

      console.log("normalCount/delayCount",res.taskOverView.normalCount,res.taskOverView.delayCount);

      this.datasources = res.taskOverView.owners;
    });
  },
  methods: {
    pieone() {
      getDashBoard({}).then(() => {
        this.unallocated = 90;
      });
    },
  },
  watch: {
    taskscreen: {
      handler() {
        var parm = {
          status: this.taskscreen,
        };
        getDashBoard(parm).then((res) => {
          this.tasktotalcounts = res.taskOverView.allCount;
          this.pieData[0]["value"] = res.taskOverView.onDoingCount;
          this.pieData[1]["value"] = res.taskOverView.unallocatedCount;
          this.pieData[2]["value"] = res.taskOverView.doneCount;
          this.pieData2[0]["value"] = res.taskOverView.delayCount;
          this.pieData2[1]["value"] = res.taskOverView.normalCount;
          this.datasources = res.taskOverView.owners;
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
#tasktotalcount {
  /* width: 100px;
  height: 300px;
  float: left; */
  padding-top: 30px;
  font-weight: bold;
}

#tasktotalcount span{
  color: #ff9900;
}
.echarts {
  width: 100%;
  min-width: 350px;
  height: 300px;
  display: inline-block;
}
.bgStyle {
  min-height: 350px;
}

.title h2{
  padding-top: 30px;
  font-weight: bold;
  font-size: 16px;
}
</style>
