<template>
  <div class="bgStyle">
    <div class="pageTitle">
      任务列表<span class="desc">包含当前筛选项目中的全部任务</span>
    </div>
    <ProjectTaskTable
      :projectId="``"
      :showFilter="true"
      :taskstatu="taskstatusselecteds"
    />
  </div>
</template>
<script>
import ProjectTaskTable from "@/views/TaskManage/components/ProjectTaskTable";
export default {
  name: "DashboardTaskList",
  props: { taskstatusselecteds: { type: Number, default: () => 0 } },
  components: {
    ProjectTaskTable,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
  watch: {
    taskstatu: {
      handler() {
        console.log(this.taskstatu);
      },
      deep: true,
      immediate: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.table {
  margin-top: 10px;
}
.desc {
  color: $dark_gray;
  margin-left: 10px;
  font-size: 12px;
}
</style>
